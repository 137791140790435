const salesReps = [
  {
    name: "Corrine Nicholls",
    phoneNumber: "+1 905-912-1590",
    email: "corrine@craft360.ca",
  },
  {
    name: "Liam Jones",
    phoneNumber: "+1 416-528-2511",
    email: "liam@craft360.ca",
  },
  {
    name: "Mergim Shena",
    phoneNumber: "+1 647-607-0815",
    email: "mergim@craft360.ca",
  },
  {
    name: "Chris Anderson",
    phoneNumber: "+1 647-244-0455",
    email: "chris@craft360.ca",
  },
  {
    name: "Ozz Baker",
    phoneNumber: "+1 647-502-2537",
    email: "ozz@craft360.ca",
  },
];

export default salesReps;
